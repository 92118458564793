import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE, SMALL_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside ConsentTemplate functional component
 */

const useStyles = makeStyles((theme) => {
	const defaultTextStyling = {
		fontSize: 16,
		fontWeight: 500,
		lineHeight: 'normal',
		letterSpacing: 0.19,
	}

	return {
		// TODO: Refactor logo to mobile first and adjust positioning to match design
		logo: {
			height: theme.spacing(40 / 8),
			width: theme.spacing(112 / 8),
			position: 'absolute',
			marginTop: theme.spacing(35 / 8),
			marginLeft: theme.spacing(40 / 8),
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(32 / 8),
				marginLeft: theme.spacing(66 / 8),
			},
			[theme.breakpoints.down('xs')]: {
				marginTop: theme.spacing(20 / 8),
				marginLeft: theme.spacing(32 / 8),
			},
		},
		containerWrapper: {
			padding: theme.spacing(100 / 8, 32 / 8, (16 + 96 + 150) / 8),
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(140 / 8, 0, (16 + 96 + 150) / 8),
			},
			[theme.breakpoints.up('lg')]: {
				padding: theme.spacing(160 / 8, 0, (16 + 96 + 150) / 8),
			},
		},
		header: {
			...defaultTextStyling,
			fontSize: 36,
			fontWeight: 'bold',
			letterSpacing: -0.77,
			marginBottom: theme.spacing(16 / 8),
			[theme.breakpoints.up('sm')]: {
				fontSize: 48,
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: 64,
				marginBottom: theme.spacing(24 / 8),
			},
		},
		date: {
			...defaultTextStyling,
			color: '#7e7e80',
			marginBottom: theme.spacing(40 / 8),
			[theme.breakpoints.up('sm')]: {
				marginBottom: theme.spacing(64 / 8),
			},
			[theme.breakpoints.up('lg')]: {
				marginBottom: theme.spacing(48 / 8),
			},
		},
		body: {
			...defaultTextStyling,
			color: '#7e7e80',
			marginBottom: theme.spacing(40 / 8),
			[theme.breakpoints.up('sm')]: {
				marginBottom: theme.spacing(40 / 8),
			},
			[theme.breakpoints.up('lg')]: {
				marginBottom: theme.spacing(48 / 8),
			},
		},
		link: {
			textDecoration: 'underline',
		},
		bodyTitle: {
			...defaultTextStyling,
			fontSize: 20,
			fontWeight: 'bold',
			letterSpacing: -0.27,
			marginBottom: theme.spacing(12 / 8),
			[theme.breakpoints.up('sm')]: {
				fontSize: 24,
			},
			[theme.breakpoints.up('lg')]: {
				fontSize: 24,
			},
		},
		actions: {
			position: 'fixed',
			bottom: 0,
			paddingBottom: 16,
			paddingTop: 96,
			width: `calc(100% - 2 * 32px)`,
			background:
				'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 50%)',
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			[theme.breakpoints.up('sm')]: {
				width: `calc((100% / 12 * 8) - ${2 * SMALL_GUTTER_SIZE}px)`,
				backgroundImage:
					'linear-gradient(to bottom, rgba(255, 255, 255, 0) -17%, #ffffff 33%)',
			},
			[theme.breakpoints.up('lg')]: {
				flexDirection: 'row',
				width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
				background:
					'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 38%)',
			},
		},
		submitButton: {
			width: '100%',
			padding: theme.spacing(16 / 8),
			[theme.breakpoints.up('lg')]: {
				width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
			},
		},
		consentBox: {
			marginLeft: theme.spacing(-9 / 8),
			display: 'flex',
			width: '100%',
			marginBottom: theme.spacing(16 / 8),
			[theme.breakpoints.up('lg')]: {
				width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
				marginBottom: 0,
			},
		},
		consentText: {
			fontSize: theme.spacing(14 / 8),
			fontWeight: 600,
			alignSelf: 'center',
		},
		underlinedText: {
			textDecoration: 'underline',
		},
		pointerCursor: {
			cursor: 'pointer',
		},
		purpleText: {
			color: theme.palette.primary.main,
		},
	}
})

export default useStyles
