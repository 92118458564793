import {Grid, Box, Typography, Link} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import React, {ReactNode, useEffect, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	TemplateContainerComponent as TemplateContainer,
	CustomCheckboxComponent as CustomCheckbox,
	CustomButtonComponent as CustomButton,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {getConsent} from '@redux/slices/documents-slice'
import {navigate} from '@redux/slices/navigation-slice'
import {CyclisLogoSvg} from '@svg/index'
import {updateConsent} from '@templates/ConsentTemplate/consent-slice'
import {LanguageContext} from '@utils/context'
import {downloadFileFromUrl} from '@utils/download-helper'
import {CommonProps} from '@utils/types'

import {consentSchema} from './consent-validation'
import useStyles from './style.hook'

/**
 * ConsentTemplate
 */
const ConsentTemplate: React.FC<CommonProps> = () => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const dispatch = useDispatch()

	const consentLoading = useSelector(
		(state: RootState) => state.consent.loading
	)
	const consentSuccess = useSelector(
		(state: RootState) => state.consent.success
	)

	const consentDocument = useSelector(
		(state: RootState) => state.documents.consent
	)
	const consentDocumentLoading = useSelector(
		(state: RootState) => state.documents.loadingConsent
	)
	const consentDocumentSuccess = useSelector(
		(state: RootState) => state.documents.consentSuccess
	)

	const submitConsent = async (): Promise<void> => {
		dispatch(updateConsent())
	}

	const handleConsentDownloadLinkClick = (): void => {
		if (!consentDocumentLoading) {
			dispatch(getConsent())
		}
	}

	useEffect(() => {
		if (consentDocument && consentDocumentSuccess) {
			downloadFileFromUrl(consentDocument)
		}
	}, [consentDocument, consentDocumentSuccess])

	useEffect(() => {
		if (consentSuccess) {
			dispatch(navigate(`/${activeLanguage}/app`))
		}
	}, [consentSuccess, activeLanguage])

	const Copy: React.FC = () => (
		<>
			<Typography className={classes.header} variant={'h1'}>
				{t('ConsentTitle')}
			</Typography>
			<Typography className={classes.date} variant={'subtitle1'}>
				{t('ConsentDate')}
			</Typography>
			<Typography className={classes.body} variant={'body1'}>
				{t('ConsentBodyPart1')}
			</Typography>
			<Typography className={classes.body} variant={'body1'}>
				{t('ConsentBodyPart2')}&nbsp;
				<Link
					className={classes.link}
					href={'mailto:privacy@cyclis.be'}
					variant={'inherit'}
				>
					privacy@cyclis.be
				</Link>
			</Typography>
			<Typography className={classes.bodyTitle} variant={'h3'}>
				{t('ConsentBodyTitle')}
			</Typography>
			<Typography className={classes.body} variant={'body1'}>
				{t('ConsentBodyPart3')}
			</Typography>
			<Typography className={classes.body} variant={'body1'}>
				{t('ConsentBodyPart4')}
			</Typography>
		</>
	)

	const Actions: React.FC = () => (
		<Formik
			initialValues={{agreedConsent: false}}
			validationSchema={consentSchema(t)}
			onSubmit={submitConsent}
		>
			{({values, handleChange, handleSubmit}): ReactNode => (
				<form onSubmit={handleSubmit}>
					<Box className={classes.actions}>
						<Box className={classes.consentBox}>
							<CustomCheckbox
								propsToDelegate={{
									checked: values.agreedConsent,
									onChange: handleChange,
									name: 'agreedConsent',
								}}
								label={
									<Typography className={classes.consentText} variant={'body1'}>
										{t('ConsentAcceptPart1')}&nbsp;
										<span
											className={classNames(
												classes.purpleText,
												classes.underlinedText,
												classes.pointerCursor
											)}
											onClick={handleConsentDownloadLinkClick}
										>
											{t('ConsentAcceptPart2')}
										</span>
									</Typography>
								}
							/>
						</Box>
						<CustomButton
							id={'consent-submit-button'}
							className={classes.submitButton}
							text={t('ConsentSubmitButton')}
							propsToDelegate={{
								type: 'submit',
								disabled: consentLoading || !values.agreedConsent,
							}}
						/>
					</Box>
				</form>
			)}
		</Formik>
	)

	return (
		<TemplateContainer>
			<CyclisLogoSvg className={classes.logo} color noLogoLink />
			<Box className={classes.containerWrapper}>
				<Grid spacing={8} justify="center" container>
					<Grid item lg={6} sm={8} xs={12}>
						{/* Copy is not final, just filling the page */}
						<Copy />
						<Actions />
					</Grid>
				</Grid>
			</Box>
		</TemplateContainer>
	)
}

ConsentTemplate.propTypes = {}

export default ConsentTemplate
